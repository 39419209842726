@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "VolvoNovum-Regular", "sans-serif";
$boldFontFamily: "VolvoNovum-Regular", "sans-serif";
$boldFontWeight: 400;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: none;

// ========
// Colors
// ========

$textColor: #141414;
$linkColor: #1C6BBA;
$labelColor: #141414;
$formColor: #141414;
$utilColor: #1C6BBA;
$priceColor: #141414;
$lightColor: #141414;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #141414;
$titleSecondaryColor: #141414;
$subtitleColor: #141414;
$subtitleSecondaryColor: #ffffff;
$successColor: #1C6BBA;
$warningColor: #f7941d;
$errorColor: #cc4b37;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #ffffff;

$bgBtnAlpha: #1C6BBA;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #141414;
$colorBtnBeta: #ffffff;
$colorBtnOutlineAlpha: #141414;
$colorBtnOutlineBeta: #1C6BBA;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #141414;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #ffffff;
$calculatorTabsTextColor: #141414;
$calculatorCloseTabs: #141414;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: #1C6BBA;

$garageBackground: #ffffff;
$garageTextColor: #141414;
$garageCarNoteColor: #ffffff;

$bgTopbarLogin: #141414;
$colorTopbarLogin: #ffffff;

$progressBarTitleBG: #1C6BBA;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #1C6BBA;
$progressBarCloseButtonBG: #1C6BBA ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #ffffff;
$paymentCalculatorClosedColor: #141414;
$paymentCalculatorPanelBG: #ffffff;
$paymentCalculatorTermWrapperBG: #141414;
$paymentCalculatorTermWrapperColor: #ffffff;
$paymentCalculatorTermSelectedWrapperBG: #1C6BBA;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #141414;
$paymentCalculatorTermColor: #ffffff;

$calculatorDropdownColor: #141414;
// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__volvo{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__volvo{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }

        .widget-register-popupElement .SignUpForm .ModalHeader .sub-text,
        .widget-login-popupElement .SignInForm .ModalHeader .sub-text{
            color: #cc4b37;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-transaction{
            color: #1C6BBA;
        }
        .widget-garage .pendingVehicle .slide-bloc {
            background-color: #f2f2f2!important;
        }

        .TransactionProgressBar--progression{
            background-color: #1C6BBA;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #1C6BBA;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#1C6BBA 55%);
        }

        .section-catalog-header .catalog-warning{
            background: #1C6BBA;
        }
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount .vehicle-payment-amount-note,
        .vehicle-item .vehicle-description .vehicle-name .vehicle-body,
        .vehicle-item .vehicle-description .vehicle-cost,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate .vehicle-payment-rate-note{
            color: #141414;
        }

        .vehicle-item .vehicle-info{
            box-shadow: none;
            border: 1px solid #707070;
            border-radius: 0px;
            background: #fff;
        }
        .vehicle-item .vehicle-description{
            border-radius: 0px;
            background: #fff;
        }
        .catalog{
            background: #ffffff!important;
        }
        .section-catalog-filter .catalog-filter-search .catalog-filter-input-search{
            border-right: 1px solid #707070;
        }
        .section-catalog-filter .catalog-filter-search,
        .section-catalog-filter .catalog-filter__trade-in-section{
            border: 1px solid #707070;
            background: #ffffff;
        }
        .section-catalog-filter .catalog-filter-search .catalog-filter-input-search input,
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-name,
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single,
        .section-catalog-filter .catalog-filter__trade-in-wrapper .trade-in-info{
            color: #141414;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #1C6BBA;
        }

        .InputText .input-control{
            color: #141414;
        }

        .selection-year-bloc__disclaimer span:first-child{
            color: #141414;
        }
        .selection-year-bloc{
            box-shadow: none;
            border-radius: 0;
            border: 1px solid #707070;
        }
        .selection-year-bloc:hover{
            border-color: #141414;
        }
        .selection-year-bloc:hover .selection-year-bloc__selectButton{
            background-color: #141414;
        }
        .selection-year-bloc__payment{
            border-top: 0;
        }

        .section-showroom-header__progressBar-stage .icon-showroom,
        .section-showroom-header__progressBar-stage{
            color: #141414;
        }
        .section-showroom-header__progressBar-stage.selected{
            color: #ffffff;
        }

        .section-showroom-header{
            box-shadow: none;
            border-bottom: 1px solid #707070;
        }
        .section-model-trim{
            border-top-color: transparent;
        }

        .payment-calculator-wrapper{
            padding-top: 10px;
            border-top: 1px solid #707070;
        }

        .purchase-methods-tab{
            border-bottom: 1px solid #707070;
        }

        .InputNumber--add.disabled-btn, 
        .InputNumber--minus.disabled-btn{
            background-color: #707070;
        }
        .InputNumber--add,
        .InputNumber--minus{
            border-radius: 0;
            color: #141414;
            border: 1px solid #707070;
        }
        .InputNumber input{
            border-radius: 0;
            color: #141414;
            border-bottom: 1px solid #707070;
            border-top: 1px solid #707070;
        }
        .sr-paymentCalculator-dropdown .Select-control{
            border: 1px solid #707070 !important;
        }
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            background-color: #ffffff!important;
            border-radius: 0;
            color: #141414!important;
            &.is-selected{
                background-color: #ffffff;
                color: #141414 !important;
            }
        }
        .new-customize-payment,
        .calculator-cash-down,
        .section-distance,
        .calculator-tradeIn{
            border-bottom: 1px solid #707070;
        }

        .calculatorColumn{
            border-right: 1px solid #707070;
        }
        .calculatorColumn:last-child {
            border-right: 0;
        }
        .showroom-build-and-price,
        .tile-payment{
            sup{
                line-height: 1.3em !important;
            }
        }
        .calculator-done{
            border-top-color: #707070;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected{
            background-color: #141414 !important; ;
            color: #ffffff;
            .price span,
            .is-light{
                color: #ffffff !important;
            }
        }
        .sr-paymentCalculator__calculatorTerm--background,
        .sr-paymentCalculator__calculatorTerm--background.selected .term{
            background-color: #1C6BBA !important;
            color: #ffffff;
        }
        .optionsResolutions__header--image{
            icon{
                color: #ffffff;
            }
        }
        .listing-tile .listing-tile-all-price,
        .InputAutoComplete .input-control,
        .TransactionAction--single .label,
        .TransactionTimeSaved--smiley,
        .price-cash,
        .vehicle-item .vehicle-description .vehicle-name,
        .vehicle-item .vehicle-payment .vehicle-payment-title,
        .separator span,
        .wrapper-date-picker,
        .listing-new-tile .listing-tile-car-save,
        .listing-used-results .number-results,
        .listing-used-filters-head .listing-used-filters-head-button p a,
        .listing-used-bodystyle.sedan:before,
        .listing-used-filters-head .listing-used-filters-head-title,
        .method-button-payment-bar button,
        .model-list li .model-label,
        .listing-used-filters-title icon,
        .listing-used-payment-search .input-search input,
        .financing-section.tile-payment-info .cashdown,
        .financing-section.tile-payment-info .tile-payment-detail,
        .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-name,
        .listing-used-filters .listing-used-filters-bloc .listing-used-filters-title,
        .Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label,
        .Select.has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: $priceColor;
        }
        .listing-used-bodystyle:hover,
        .model-button:not(.selected):hover,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button:hover{
            border-color: #d5d5d5;
        }
        .method-button-payment-bar button:after,
        .TradeInTile--content-estimate,
        .listing-used-filters-head .listing-used-filters-head-button p.current-filter:after{
            background-color: $borderColorForm;
        }
        .payment-summary-section .listing-tile-all-price{
            border-top-color: $borderColorForm;
            color: #707070;
        }
        .listing-new-tile .listing-tile-all-price{
            border-bottom-color: $borderColorForm;
        }
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-odometer,
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
        .listing-new-tile .listing-tile-vin,
        .listing-new-tile .listing-tile-package-description,
        .listing-new-tile .listing-new-tile-drivePowerTrains,
        .listing-tile .listing-tile-drivePowerTrains,
        .styled-placeholder.dropdown.not-empty .input-label,
        .VehicleDisclaimersContent .disclaimer,
        .calculatorColumn__disclaimer,
        .vehicle-overview__vehicle-title .vehicle-stockno,
        .vehicle-overview__vehicle-title .vehicle-vinno,
        .TradeInTile--content-official-subtitle,
        .purchase-methods-cash .obligationsTotal .amountLineItem .sub,
        .purchase-methods-cash .pdsf_calculator .amountLineItem .sub,
        .financing-section.tile-payment-info .tile-payment-detail.small,
        .selection-year-bloc__paymentMethod--values .cashdown-highlight,
        .selection-year-bloc__paymentMethod--values .terms-highlight,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit{
            color: #707070;
        }
        .TransactionSummaryUnit--single:nth-child(2){
            background-color: #fafafa;
        }
        .TransactionSummaryUnit--single:nth-child(2):before{
            border-left-color: #fafafa;
        }
        .TransactionSummaryUnit--single:nth-child(3){
            background-color: #d5d5d5;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            background-color: #707070;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate:before{
            border-left-color: #707070;
        }
        .separator:after, .separator:before{
            background: #707070;
        }
        .listing-used-bodystyle,
        .model-button,
        .selection-year-bloc__disclaimer,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button{
            background-color: #fafafa;
            color: $textColor
        }
        .listing-used-payment-search .input-search__clearbtn{
            color: #707070;
            border-color: #707070;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected,
        .listing-used-bodystyle.selected,
        .model-button.selected.is-price{
            border: 2px solid #1C6BBA;
            color: #1C6BBA;
            background: #fff;
        }
        .InputText.styled-placeholder.dark-theme>.input-control{
            box-shadow: none;
        }

        .calculator-tradeIn .input-format-currency {
            border: 0;
            border-radius: 0;
        }
        .InputText .input-control,
        .input-format-currency input:focus{
            border: 1px solid #707070!important;
            border-radius: 0;
        }
        .sr-button-1,
        .sr-button-2{
            box-shadow: none!important;
        }
        .sr-paymentCalculator-EmphasisColor{
            color: #141414!important;
        }
        .purchase-methods-cash .purchase-detail-line.price_total .label{
            color: #ffffff!important;
        }

        .style-trim__lists__item .style-trim-btn{
            color: #141414;
        }
        .style-trim__lists__item.selected .style-trim-btn{
            color: #ffffff;
        }
        .style-trim__lists__item,
        .style-trim__lists__item.selected{
            box-shadow: none;
            border-radius: 0;
        }

        .style-color__button.selected{
            border: 3px solid #141414;
        }
        .style-color__label,
        .car-equipments_summary .features-bloc{
            color: #141414;
        }

        .style-slider .slider-decorator--bottom icon{
            font-size: 25px;
            border: 0;
            background-color: transparent;
        }

        .bloc-options__single{
            border-radius: 0;
            box-shadow: none;
        }
        .bloc-options__single.selected{
            background-color: #141414;
        }
        .bloc-options__single--footer.selected .option-choose-cta {
            background-color: #1C6BBA;
            color: #ffffff;
            border-color: #1C6BBA;
        }
        .bloc-options__single--footer .option-choose-cta{
            border-radius: 0;
            box-shadow: none;
            border: 1px solid #1C6BBA;
            background: #ffffff;
        }
        .bloc-options__single--footer .option-choose-cta icon{
            color: #1C6BBA;
        }
        .bloc-options__single--footer.selected .option-choose-cta icon{
            color: #ffffff;
        }
        .bloc-options__single--body .option-list .option,
        .bloc-options__single--body .option-list .option icon{
            color: #141414;
        }
        .message-handler.warning{
            background-color: #1C6BBA;
            box-shadow: none;
        }

        .section-summary__content .trim,
        .financing-section.tile-payment-info .tile-frequency,
        .financing-section.tile-payment-info .tile-payment-amount span,
        .financing-section.tile-payment-info .tile-payment-rate,
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .financing-section.tile-payment-info .tile-payment-cashdown,
        .tile-payment-info .payment-rebates .payment-rebate,
        .car-equipments_summary .specs-bloc,
        .car-equipments_summary .specs-bloc--content span,
        .cash-section.tile-payment-info .payment-row{
            color: #141414;
        }
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight{
            display: inline-block;
        }
        .section-summary__content .car-equipments_summary>*{
            border: 1px solid #707070;
            border-radius: 0;
            box-shadow: none;
        }

        .accessory_wrapper.single .accessory_wrapper--check icon{
            color: #1C6BBA;
        }

        .sr-button-outline-2.is-alternate{
            color:#ffffff;
        }

        .car-accessories .accessory-bloc--image{
            background-color: #707070;
            border-radius: 0;
        }

        .section-checkout-header__progressBar-stage .picto-showroom,
        .section-checkout-header__progressBar-stage{
            color: #707070;
        }
        .section-checkout-header__progressBar-stage{
            border: 2px solid #707070;
        }
        .section-checkout-header__progressBar-stage.selected{
            background-color: #141414;
            border-color: #141414;
        }
        .section-checkout-header__progressBar-stage:after{
            background-color: #707070;
        }

        .forms-container{
            box-shadow: none;
            border-radius: 0;
        }
        .checkout-payment-select .checkout__payment-options-list:nth-child(odd), 
        .checkout__section-delivery .checkout__payment-options-list:nth-child(odd){
            background-color: #f2f2f2;
        }
        .no-delivery-option{
            background: #f2f2f2;
        }
        .checkout__section-delivery:before{
            background-color: #f2f2f2;
        }
        label{
            color: #141414;
        }
        .sr-sidebarsummary .sr-text-regular{
            color: #141414;
        }
        .checkout__section-delivery{
            border: 0;
        }
        .checkout__inner-content {
            border-radius: 0;
            box-shadow: none;
            border: 1px solid #707070;
        }
        .sr-sidebarsummary .sr-text-bold{
            color: #141414!important;
        }
        .sr-title{
            border-top: 4px solid #141414;
            color: #141414!important;
        }
        .sr-sidebarsummary .sr-text-thin,
        .sr-sidebarsummary .sr-text-bold{
            color: #141414!important;
        }
        .vehicle__totalPayment{
            border: 1px solid #707070!important;
        }
        .sr-sidebarsummary .sr-text-regular,
        .sr-sidebarsummary .sr-title{
            color: #141414!important;
        }
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child{
            color: #1C6BBA;
        }
        .financing__content {
            box-shadow: none;
            border: 1px solid #707070;
        }
        .financingBlockForm {
            background-color: #f2f2f2;
            border-bottom: 1px solid #707070;
        }
        .financingBlockForm.is-open{
            background-color: #ffffff;
        }
        .financingBlockForm.is-open+.financingBlockForm{
            box-shadow: none;
        }
        .message-handler.error {
            background-color: #cc4b37;
        }
        .financing__content-errors-message,
        .InputText .errors-input{
            color: #cc4b37;
        }
        .confirm_deposit--title,
        .confirm_deposit--icon icon,
        .confirm_financing--icon icon,
        .confirm_financing--title,
        .confirm_tradein--icon icon,
        .confirm_tradein--title
        {
            color: #1C6BBA;
        }
        
        .confirm_deposit--icon,
        .confirm_financing--icon,
        .confirm_tradein--icon{
            border: 1px solid #1C6BBA;
        }
        .protection-single--content .protection-price--details{
            color: #141414;
        }
        .financingBlockForm:last-child{
            box-shadow: none;
            border: 0;
        }
        .protection-category--wrapper.is-open{
            box-shadow: none;
            border: 1px solid #707070;
        }
        .protection-category--wrapper.is-open .protection-category--intro {
            border-radius: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .protection-category--intro{
            border-radius: 0;
        }
        .protection-single--content .protection-cta-selector{
            border-radius: 0;
            box-shadow: none;
        }
        .protection-category--wrapper.is-validated .protection-category--intro{
            border-radius: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .Appointment__content .datepicker.styled-placeholder{
            border: 1px solid #707070;
            border-radius: 0;
        }
        .Appointment__content .button-cta-wrapper--single:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .Appointment__content .button-cta-wrapper--single.selected {
            background-color: #141414;
            border: 1px solid #141414;
        }
        .Appointment__content .button-cta-wrapper--single.selected:hover{
            color: $textColor;
        }
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon{
            color: $utilColor;
        }
        .Appointment__content .button-cta-wrapper--single:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .Appointment__content .button-cta-wrapper--single{
            box-shadow: none;
        }
        .Appointment__content .button-cta-wrapper--single:hover{
            background-color: #fafafa;
        }
        .Appointment__content .appointment__steps--single .timeSaved,
        .Appointment__content .appointment__steps--single .timeSaved icon{
            color: #141414;
        }
        .TransactionTimeSaved--wrapper{
            color: #141414;
        }
        .login-content a{
            color: #ffffff;
        }
        .listing-used{
            background-color: #ffffff;
        }
        .listing-tile-note,
        .listing-tile-note .vehicle-note{
            background: #ffffff;
        }
        .vdp-used-form .sr-title-2.is-alternate{
            color: $subtitleSecondaryColor;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #1C6BBA!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#1C6BBA 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #1C6BBA !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #1C6BBA !important;
            .icon,.icon-Crochet{
                color: #1C6BBA !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #1C6BBA;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #1C6BBA;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #1C6BBA !important;
            }
        }
        .doneLabel {
            color: #1C6BBA !important;
            .icon-Crochet{
                color: #1C6BBA !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #1C6BBA !important;
            }
        }
        .has-success-background{
            background-color: #1C6BBA !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .listing-tile{
            .listing-tile-car-save{
                background: #1c6bba !important;
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__volvo{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}